import 'support-js/support/override'
import { App } from 'components/app'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import 'bootstrap/scss/bootstrap'
import 'bootstrap/dist/js/bootstrap'
import '@mdi/font/scss/materialdesignicons.scss'
// import '@kfonts/nanum-barun-gothic'
// import '@kfonts/nanum-square-round'
import '@fontsource/nunito-sans'
import { configure } from 'mobx'

configure({
    useProxies: "never",
    enforceActions: 'observed',
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false
})

document.addEventListener('DOMContentLoaded', (event: Event) => {
    createRoot(document.getElementById('bootpay')).render(<App/>)
})