import React, { Fragment, lazy, Suspense } from 'react'
import { BootpayComponent } from 'components/bootpay'
import { action, makeObservable, observable } from 'mobx'
// @ts-ignore
import { DocsVersion } from 'stores/env.ts.erb'
import $ from 'jquery'
import _ from 'lodash'
import { DocsSearch } from 'components/search'
import { inject, observer } from 'mobx-react'
import { BootSkeleton } from 'support-js/react/components/boot-skeleton'
import Prism from 'prismjs'

const SdkIntroduce                     = lazy(() => import('components/sdk/introduce').then(({ SdkIntroduce }) => ({ default: SdkIntroduce })))
const SdkConfigure                     = lazy(() => import('components/sdk/configure').then(({ SdkConfigure }) => ({ default: SdkConfigure })))
const SdkFlow                          = lazy(() => import('components/sdk/flow').then(({ SdkFlow }) => ({ default: SdkFlow })))
// const SdkSandbox = lazy(() => import("components/sdk/sandbox").then(({ SdkSandbox }) => ({ default: SdkSandbox })))
const MigrationFeature                 = lazy(() => import("components/migration/feature").then(({ MigrationFeature }) => ({ default: MigrationFeature })))
const MigrationGuide                   = lazy(() => import("components/migration/guide").then(({ MigrationGuide }) => ({ default: MigrationGuide })))
const StepDevelopment                  = lazy(() => import("components/step/development").then(({ StepDevelopment }) => ({ default: StepDevelopment })))
const StepProduction                   = lazy(() => import("components/step/production").then(({ StepProduction }) => ({ default: StepProduction })))
const SdkRequest                       = lazy(() => import('components/sdk/request').then(({ SdkRequest }) => ({ default: SdkRequest })))
const SdkClientConfirmError            = lazy(() => import('components/sdk/client-confirm-error').then(({ SdkClientConfirmError }) => ({ default: SdkClientConfirmError })))
const SdkClientRequest                 = lazy(() => import('components/sdk/client-request').then(({ SdkClientRequest }) => ({ default: SdkClientRequest })))
const SdkClientRequestTotal            = lazy(() => import("components/sdk/request-total").then(({ SdkClientRequestTotal }) => ({ default: SdkClientRequestTotal })))
const SdkClientRequestBio              = lazy(() => import("components/sdk/request-bio").then(({ SdkClientRequestBio }) => ({ default: SdkClientRequestBio })))
const SdkClientRequestPassword         = lazy(() => import("components/sdk/request-password").then(({ SdkClientRequestPassword }) => ({ default: SdkClientRequestPassword })))
const SdkClientRequestWebapp           = lazy(() => import("components/sdk/request-webapp").then(({ SdkClientRequestWebapp }) => ({ default: SdkClientRequestWebapp })))
const SdkClientConfirmResponse         = lazy(() => import("components/sdk/client-confirm-response").then(({ SdkClientConfirmResponse }) => ({ default: SdkClientConfirmResponse })))
const SdkClientConfirmResponseRedirect = lazy(() => import("components/sdk/client-confirm-response-redirect").then(({ SdkClientConfirmResponseRedirect }) => ({ default: SdkClientConfirmResponseRedirect })))
const SdkClientResponse                = lazy(() => import('components/sdk/client-response').then(({ SdkClientResponse }) => ({ default: SdkClientResponse })))
const SdkClientResponseRedirect        = lazy(() => import('components/sdk/client-response-redirect').then(({ SdkClientResponseRedirect }) => ({ default: SdkClientResponseRedirect })))
const SdkSeparatelyConfirmed           = lazy(() => import('components/sdk/separately-confirmed').then(({ SdkSeparatelyConfirmed }) => ({ default: SdkSeparatelyConfirmed })))
const SdkPaymentOpenType               = lazy(() => import('components/sdk/payment-open-type').then(({ SdkPaymentOpenType }) => ({ default: SdkPaymentOpenType })))
const RestApiAccessToken               = lazy(() => import('components/rest-api/access-token').then(({ RestApiAccessToken }) => ({ default: RestApiAccessToken })))
const RestApiCancel                    = lazy(() => import('components/rest-api/cancel').then(({ RestApiCancel }) => ({ default: RestApiCancel })))
const RestApiUnpublishVirtualBank      = lazy(() => import('components/rest-api/unpublish-virtual-bank').then(({ RestApiUnpublishVirtualBank }) => ({ default: RestApiUnpublishVirtualBank })))
const RestApiError                     = lazy(() => import('components/rest-api/error').then(({ RestApiError }) => ({ default: RestApiError })))
const RestApiHttpStatus                = lazy(() => import('components/rest-api/http-status').then(({ RestApiHttpStatus }) => ({ default: RestApiHttpStatus })))
const RestApiIntroduce                 = lazy(() => import('components/rest-api/introduce').then(({ RestApiIntroduce }) => ({ default: RestApiIntroduce })))
const RestApiLookupReceipt             = lazy(() => import('components/rest-api/lookup-receipt').then(({ RestApiLookupReceipt }) => ({ default: RestApiLookupReceipt })))
const RestApiUserToken                 = lazy(() => import('components/rest-api/user-token').then(({ RestApiUserToken }) => ({ default: RestApiUserToken })))
const RestApiConfirm                   = lazy(() => import('components/rest-api/confirm').then(({ RestApiConfirm }) => ({ default: RestApiConfirm })))

const OrderLink      = lazy(() => import('components/order/link').then(({ OrderLink }) => ({ default: OrderLink })))
// const OrderLinkPay                               = lazy(() => import('components/order/link-pay').then(({ OrderLinkPay }) => ({ default: OrderLinkPay })))
const OrderCardKeyin = lazy(() => import('components/order/card-keyin').then(({ OrderCardKeyin }) => ({ default: OrderCardKeyin })))

const AuthenticateIntroduce      = lazy(() => import('components/authenticate/introduce').then(({ AuthenticateIntroduce }) => ({ default: AuthenticateIntroduce })))
const AuthenticateRequest        = lazy(() => import('components/authenticate/request').then(({ AuthenticateRequest }) => ({ default: AuthenticateRequest })))
const AuthenticateApi            = lazy(() => import('components/authenticate/api').then(({ AuthenticateApi }) => ({ default: AuthenticateApi })))
const AuthenticateRestApiRequest = lazy(() => import('components/authenticate/rest-api/request').then(({ AuthenticateRestApiRequest }) => ({ default: AuthenticateRestApiRequest })))
const AuthenticateRestApiRealarm = lazy(() => import('components/authenticate/rest-api/realarm').then(({ AuthenticateRestApiRealarm }) => ({ default: AuthenticateRestApiRealarm })))
const AuthenticateRestApiConfirm = lazy(() => import('components/authenticate/rest-api/confirm').then(({ AuthenticateRestApiConfirm }) => ({ default: AuthenticateRestApiConfirm })))

const SubscriptionIntroduce                      = lazy(() => import('components/subscription/introduce').then(({ SubscriptionIntroduce }) => ({ default: SubscriptionIntroduce })))
const SubscriptionClientSdk                      = lazy(() => import('components/subscription/client-sdk').then(({ SubscriptionClientSdk }) => ({ default: SubscriptionClientSdk })))
const SubscriptionRestApiLookupBillingKey        = lazy(() => import('components/subscription/rest-api-lookup-billing-key').then(({ SubscriptionRestApiLookupBillingKey }) => ({ default: SubscriptionRestApiLookupBillingKey })))
const SubscriptionRestApiCard                    = lazy(() => import('components/subscription/rest-api-card').then(({ SubscriptionRestApiCard }) => ({ default: SubscriptionRestApiCard })))
const SubscriptionRestApiPayment                 = lazy(() => import('components/subscription/rest-api-payment').then(({ SubscriptionRestApiPayment }) => ({ default: SubscriptionRestApiPayment })))
const SubscribeRestApiBillingKeyRevoke           = lazy(() => import('components/subscription/rest-api-billing-key-revoke').then(({ SubscribeRestApiBillingKeyRevoke }) => ({ default: SubscribeRestApiBillingKeyRevoke })))
const SubscriptionRestApiPaymentReserve          = lazy(() => import('components/subscription/rest-api-payment-reserve').then(({ SubscriptionRestApiPaymentReserve }) => ({ default: SubscriptionRestApiPaymentReserve })))
const SubscriptionRestApiCancelReserve           = lazy(() => import('components/subscription/rest-api-cancel-reserve').then(({ SubscriptionRestApiCancelReserve }) => ({ default: SubscriptionRestApiCancelReserve })))
const SubscriptionRestApiPaymentReserveIntroduce = lazy(() => import('components/subscription/rest-api-payment-reserve-introduce').then(({ SubscriptionRestApiPaymentReserveIntroduce }) => ({ default: SubscriptionRestApiPaymentReserveIntroduce })))
const SubscriptionRestApiPaymentReserveRolling   = lazy(() => import('components/subscription/rest-api-payment-reserve-rolling').then(({ SubscriptionRestApiPaymentReserveRolling }) => ({ default: SubscriptionRestApiPaymentReserveRolling })))
const SubscriptionRestApiPaymentReserveLookup    = lazy(() => import('components/subscription/rest-api-payment-reserve-lookup').then(({ SubscriptionRestApiPaymentReserveLookup }) => ({ default: SubscriptionRestApiPaymentReserveLookup })))
const ThirdPartyOnestore                         = lazy(() => import('components/third-party/onestore').then(({ ThirdPartyOnestore }) => ({ default: ThirdPartyOnestore })))
const ThirdPartyTelegram                         = lazy(() => import('components/third-party/telegram').then(({ ThirdPartyTelegram }) => ({ default: ThirdPartyTelegram })))
const ThirdPartyWebhook                          = lazy(() => import('components/third-party/webhook').then(({ ThirdPartyWebhook }) => ({ default: ThirdPartyWebhook })))
const ThirdPartyVbank                            = lazy(() => import('components/third-party/vbank').then(({ ThirdPartyVbank }) => ({ default: ThirdPartyVbank })))

const EscrowIntroduce                 = lazy(() => import('components/escrow/introduce').then(({ EscrowIntroduce }) => ({ default: EscrowIntroduce })))
const EscrowRequest                   = lazy(() => import('components/escrow/request').then(({ EscrowRequest }) => ({ default: EscrowRequest })))
const EscrowShipping                  = lazy(() => import('components/escrow/shipping').then(({ EscrowShipping }) => ({ default: EscrowShipping })))
const EscrowConfirm                   = lazy(() => import('components/escrow/confirm').then(({ EscrowConfirm }) => ({ default: EscrowConfirm })))
const CashReceiptIntroduce            = lazy(() => import('components/cash-receipt/introduce').then(({ CashReceiptIntroduce }) => ({ default: CashReceiptIntroduce })))
const CashReceiptPublishOnReceipt     = lazy(() => import('components/cash-receipt/publish-on-receipt').then(({ CashReceiptPublishOnReceipt }) => ({ default: CashReceiptPublishOnReceipt })))
const CashReceiptCancelOnReceipt      = lazy(() => import('components/cash-receipt/cancel-on-receipt').then(({ CashReceiptCancelOnReceipt }) => ({ default: CashReceiptCancelOnReceipt })))
const CashReceiptPublish              = lazy(() => import('components/cash-receipt/publish').then(({ CashReceiptPublish }) => ({ default: CashReceiptPublish })))
const CashReceiptCancel               = lazy(() => import('components/cash-receipt/cancel').then(({ CashReceiptCancel }) => ({ default: CashReceiptCancel })))
const EnumIntroduce                   = lazy(() => import('components/enum/introduce').then(({ EnumIntroduce }) => ({ default: EnumIntroduce })))
const EnumPayment                     = lazy(() => import('components/enum/payment').then(({ EnumPayment }) => ({ default: EnumPayment })))
const EnumBank                        = lazy(() => import('components/enum/bank').then(({ EnumBank }) => ({ default: EnumBank })))
const EnumCard                        = lazy(() => import('components/enum/card').then(({ EnumCard }) => ({ default: EnumCard })))
const EnumEscrow                      = lazy(() => import('components/enum/escrow').then(({ EnumEscrow }) => ({ default: EnumEscrow })))
const EnumError                       = lazy(() => import('components/enum/error').then(({ EnumError }) => ({ default: EnumError })))
const SubscriptionBillingKeyGetAuth   = lazy(() => import("components/subscription/billing-key-get-auth").then(({ SubscriptionBillingKeyGetAuth }) => ({ default: SubscriptionBillingKeyGetAuth })))
const SubscriptionBillingKeyGetNoAuth = lazy(() => import("components/subscription/billing-key-get-noauth").then(({ SubscriptionBillingKeyGetNoAuth }) => ({ default: SubscriptionBillingKeyGetNoAuth })))

@inject('store')
@observer
export class Main extends BootpayComponent {
    @observable cursor
    @observable searchResult: Array<any>
    docs
    scrollEventName
    setTimeout
    searchRef
    hashChangeTimeoutInstance = undefined

    constructor(props) {
        super(props)
        makeObservable(this)
        this.$template       = require('main')
        this.scrollEventName = 'scroll.BootpayMainDocs'
        this.setTimeout      = undefined
        this.searchResult    = []
        this.updateBackendLanguage(this.getQuery('backend') === 'undefined' ? this.$session.backendLanguage : this.getQuery('backend', this.$session.backendLanguage))
        this.updateFrontLanguage(this.getQuery('front') === 'undefined' ? this.$session.frontLanguage : this.getQuery('front', this.$session.frontLanguage))
        this.docs   = [
            {
                name:      '부트페이 개발매뉴얼',
                component: <SdkIntroduce id="introduce"/>,
                id:        'introduce',
                focus:     false
            },
            // {
            //     name: 'PG계약 전 개발하기',
            //     component: <SdkSandbox id="sandbox"/>,
            //     id: 'sandbox',
            //     focus: false
            // },
            {
                name:      '연동 전 설정하기',
                component: <SdkConfigure id="configure"/>,
                id:        'configure',
                focus:     false
            },
            {
                name:      '결제 순서도',
                component: <SdkFlow id="flow"/>,
                id:        'flow',
                focus:     false
            },
            {
                name:     'v1 &#8594; v2 마이그레이션',
                focus:    false,
                children: [
                    {
                        name:      '바뀐점',
                        component: <MigrationFeature id="migration-feature"/>,
                        id:        'migration-feature',
                        focus:     false
                    },
                    {
                        name:      '마이그레이션 가이드',
                        component: <MigrationGuide id="migration-guide"/>,
                        id:        'migration-guide',
                        focus:     false
                    },
                ]
            },
            {
                name:     '부트페이 연동 시나리오',
                focus:    false,
                children: [
                    {
                        name:      '가입 후 먼저 개발하기',
                        component: <StepDevelopment id="step-development"/>,
                        id:        'step-development',
                        focus:     false
                    },
                    {
                        name:      '서비스 오픈하기',
                        component: <StepProduction id="step-production"/>,
                        id:        'step-production',
                        focus:     false
                    },
                ]
            },
            {
                name:     '클라이언트 연동하기',
                focus:    false,
                children: [
                    {
                        name:      '설치하기',
                        component: <SdkRequest id="request"/>,
                        id:        'request',
                        focus:     false
                    },
                    {
                        name:      '1. 일반결제 구현하기',
                        id:        'request-payment',
                        component: <SdkClientRequest id="request-payment"/>,
                        focus:     false
                    },
                    {
                        name:      '2. 통합결제 구현하기',
                        id:        'request-payment-total',
                        component: <SdkClientRequestTotal id="request-payment-total"/>,
                        focus:     false
                    },
                    {
                        name:      '3. 생체인증 결제 구현하기',
                        id:        'request-payment-bio',
                        component: <SdkClientRequestBio id="request-payment-bio"/>,
                        focus:     false
                    },
                    {
                        name:      '4. 비밀번호 결제 구현하기',
                        id:        'request-payment-password',
                        component: <SdkClientRequestPassword id="request-payment-password"/>,
                        focus:     false
                    },
                    {
                        name:      '5. 웹앱으로 연동하기',
                        id:        'request-payment-webapp',
                        component: <SdkClientRequestWebapp id="request-payment-webapp"/>,
                        focus:     false
                    },
                ]
            },
            {
                name:     '클라이언트 응답처리',
                focus:    false,
                children: [
                    {
                        name:      '결제 승인 / 에러 이벤트',
                        id:        'confirm-payment',
                        component: <SdkClientConfirmError id="confirm-payment"/>,
                        focus:     false
                    },

                    {
                        name:      '(선택) 결제 분리 승인',
                        id:        'separately-confirmed',
                        component: <SdkSeparatelyConfirmed id="separately-confirmed"/>,
                        focus:     false
                    },
                    {
                        name:      '결제창 OpenType',
                        id:        'payment-open-type',
                        component: <SdkPaymentOpenType id="payment-open-type"/>,
                        focus:     false
                    },
                    {
                        name:      `결제 승인 전 데이터 <span class="fs-9 text-grey">iFrame,Popup</span>`,
                        id:        'before-confirm-response',
                        component: <SdkClientConfirmResponse id="before-confirm-response"/>,
                        focus:     false
                    },
                    {
                        name:      `결제 승인 전 데이터 <span class="fs-9 text-grey">Redirect</span>`,
                        id:        'before-confirm-response-redirect',
                        component: <SdkClientConfirmResponseRedirect id="before-confirm-response-redirect"/>,
                        focus:     false
                    },
                    {
                        name:      `결제 완료 데이터 <span class="fs-9 text-grey">iFrame,Popup</span>`,
                        id:        'confirm-response',
                        component: <SdkClientResponse id="confirm-response"/>,
                        focus:     false
                    },
                    {
                        name:      `결제 완료 데이터 <span class="fs-9 text-grey">Redirect</span>`,
                        id:        'confirm-response-redirect',
                        component: <SdkClientResponseRedirect id="confirm-response-redirect"/>,
                        focus:     false
                    },
                ]
            },
            {
                name:     '서버 연동하기',
                focus:    false,
                children: [
                    {
                        name:      '설치하기',
                        id:        'rest-api-introduce',
                        component: <RestApiIntroduce id="rest-api-introduce"/>,
                        focus:     false
                    },
                    {
                        name:      '토큰 발급 받기',
                        id:        'rest-api-access-token',
                        component: <RestApiAccessToken id="rest-api-access-token"/>,
                        focus:     false
                    },
                    {
                        name:      '(공통) 응답처리',
                        id:        'rest-api-http-status',
                        component: <RestApiHttpStatus id="rest-api-http-status"/>,
                        focus:     false
                    },
                    {
                        name:      '1. 결제 단건조회',
                        id:        'rest-api-lookup-receipt',
                        component: <RestApiLookupReceipt id="rest-api-lookup-receipt"/>,
                        focus:     false
                    },
                    {
                        name:      '2. 결제 승인하기',
                        id:        'rest-api-confirm',
                        component: <RestApiConfirm id="rest-api-confirm"/>,
                        focus:     false
                    },
                    {
                        name:      '3-1. 결제 취소하기',
                        id:        'rest-api-cancel',
                        component: <RestApiCancel id="rest-api-cancel"/>,
                        focus:     false
                    },
                    {
                        name:      '3-2. 가상계좌 발급취소하기',
                        id:        'rest-api-unpublish-virtual-bank',
                        component: <RestApiUnpublishVirtualBank id="rest-api-unpublish-virtual-bank"/>,
                        focus:     false
                    },
                    {
                        name:      '4. 구매자 토큰 발급받기',
                        id:        'rest-api-user-token',
                        component: <RestApiUserToken id="rest-api-user-token"/>,
                        focus:     false
                    },
                    {
                        name:      'ERROR처리',
                        id:        'rest-api-error',
                        component: <RestApiError id="rest-api-error"/>,
                        focus:     false
                    },
                ]
            },
            {
                name:     '결제 결과 통지 받기',
                focus:    false,
                children: [
                    {
                        name:      '가상계좌 통지 받기',
                        id:        'third-party-vbank',
                        component: <ThirdPartyVbank id="third-party-vbank"/>
                    },
                    {
                        name:      '서버로 통지 받기',
                        id:        'third-party-webhook',
                        component: <ThirdPartyWebhook id="third-party-webhook"/>
                    },
                    {
                        name:      'Telegram으로 통지 받기',
                        id:        'third-party-telegram',
                        component: <ThirdPartyTelegram id="third-party-telegram"/>
                    },
                    {
                        name:      '원스토어로 통지 하기',
                        id:        'third-party-onestore',
                        component: <ThirdPartyOnestore id="third-party-onestore"/>
                    },
                ]
            },
            {
                name:     '카드 자동 결제',
                focus:    false,
                children: [
                    {
                        name:      '시작하기',
                        id:        'subscription-introduce',
                        component: <SubscriptionIntroduce id="subscription-introduce"/>
                    },
                    {
                        name:      '1-1. 빌링키 발급받기 (인증)',
                        id:        'subscription-billing-key-get-auth',
                        component: <SubscriptionBillingKeyGetAuth id="subscription-billing-key-get-auth"/>
                    },
                    {
                        name:      '1-2. 빌링키 발급받기 (비인증)',
                        id:        'subscription-billing-key-get-noauth',
                        component: <SubscriptionBillingKeyGetNoAuth id="subscription-billing-key-get-noauth"/>
                    },

                    {
                        name:      '2. 빌링키 조회하기',
                        id:        'subscription-rest-api-lookup-billing-key',
                        component: <SubscriptionRestApiLookupBillingKey id="subscription-rest-api-lookup-billing-key"/>
                    },
                    {
                        name:      '3. 빌링키로 결제 요청하기',
                        id:        'rest-api-subscribe-payment',
                        component: <SubscriptionRestApiPayment id="rest-api-subscribe-payment"/>,
                        focus:     false
                    },
                    {
                        name:      '4-1. 결제 예약하기 (intro)',
                        id:        'rest-api-subscribe-payment-reserve-introduce',
                        component: <SubscriptionRestApiPaymentReserveIntroduce
                                       id="rest-api-subscribe-payment-reserve-introduce"/>
                    },
                    {
                        name:      '4-2. 결제 예약 요청하기',
                        id:        'rest-api-subscribe-payment-reserve',
                        component: <SubscriptionRestApiPaymentReserve id="rest-api-subscribe-payment-reserve"/>
                    },
                    {
                        name:      '4-3. 결제 예약 취소하기',
                        id:        'rest-api-subscribe-reserve-cancel',
                        component: <SubscriptionRestApiCancelReserve id="rest-api-subscribe-reserve-cancel"/>
                    },
                    {
                        name:      '4-4. 예약결제 조회하기',
                        id:        'rest-api-subscribe-reserve-lookup',
                        component: <SubscriptionRestApiPaymentReserveLookup id="rest-api-subscribe-reserve-lookup"/>
                    },
                    {
                        name:      '5. 자동결제 반복하기',
                        id:        'rest-api-subscribe-reserve-rolling',
                        component: <SubscriptionRestApiPaymentReserveRolling id="rest-api-subscribe-reserve-rolling"/>
                    },
                    {
                        name:      '6. 빌링키 발급 취소하기',
                        id:        'rest-api-subscribe-billing-key-revoke',
                        component: <SubscribeRestApiBillingKeyRevoke id="rest-api-subscribe-billing-key-revoke"/>,
                        focus:     false
                    },
                ]
            },
            {
                name:     '주문결제',
                focus:    false,
                children: [
                    {
                        name:      '링크페이',
                        id:        'order-link',
                        component: <OrderLink id="order-link"/>
                    },
                    {
                        name:      '카드 수기 결제',
                        id:        'order-card-keyin',
                        component: <OrderCardKeyin id="order-card-keyin"/>
                    },
                ]
            },
            {
                name:     '본인인증',
                focus:    false,
                children: [
                    {
                        name:      '시작하기',
                        id:        'authenticate-introduce',
                        component: <AuthenticateIntroduce id="authenticate-introduce"/>
                    },
                    {
                        name:      '1-1. 본인인증 요청하기',
                        id:        'authenticate-request',
                        component: <AuthenticateRequest id="authenticate-request"/>
                    },
                    {
                        name:      '1-2. 본인인증 결과 조회',
                        id:        'authenticate-api',
                        component: <AuthenticateApi id="authenticate-api"/>
                    },
                    {
                        // name:      `2-1. 본인인증 요청하기<code class="fs-9 text-danger ms-1">REST API</code>`,
                        name:      `2-1. 본인인증 요청하기`,
                        id:        'authenticate-rest-api-request',
                        component: <AuthenticateRestApiRequest id="authenticate-rest-api-request"/>
                    },
                    {
                        // name:      `2-2. 본인인증 SMS OTP 재전송<code class="fs-9 text-danger ms-1">REST API</code>`,
                        name:      `2-2. 본인인증 SMS 재전송`,
                        id:        'authenticate-rest-api-realarm',
                        component: <AuthenticateRestApiRealarm id="authenticate-rest-api-realarm"/>
                    },
                    {
                        // name:      '2-3. 본인인증 승인<code class="fs-9 text-danger ms-1">REST API</code>',
                        name:      '2-3. 본인인증 승인',
                        id:        'authenticate-rest-api-confirm',
                        component: <AuthenticateRestApiConfirm id="authenticate-rest-api-confirm"/>
                    }
                ]
            },
            {
                name:     '에스크로',
                focus:    false,
                children: [
                    {
                        name:      '에스크로 시작하기',
                        id:        'escrow-introduce',
                        component: <EscrowIntroduce id="escrow-introduce"/>
                    },
                    {
                        name:      '에스크로 결제요청하기',
                        id:        'escrow-request',
                        component: <EscrowRequest id="escrow-request"/>
                    },
                    {
                        name:      '배송정보 보내기',
                        id:        'escrow-shipping',
                        component: <EscrowShipping id="escrow-shipping"/>
                    },
                    {
                        name:      '구매승인/거절',
                        id:        'escrow-confirm',
                        component: <EscrowConfirm id="escrow-confirm"/>
                    }
                ]
            },
            {
                name:     '현금영수증',
                focus:    false,
                children: [
                    {
                        name:      '현금영수증 발행소개',
                        id:        'cash-receipt-introduce',
                        component: <CashReceiptIntroduce id="cash-receipt-introduce"/>
                    },
                    {
                        name:      'receipt_id로 현금영수증 발행',
                        id:        'cash-receipt-publish-on-receipt',
                        component: <CashReceiptPublishOnReceipt id="cash-receipt-publish-on-receipt"/>
                    },
                    {
                        name:      'receipt_id로 현금영수증 취소',
                        id:        'cash-receipt-cancel-on-receipt',
                        component: <CashReceiptCancelOnReceipt id="cash-receipt-cancel-on-receipt"/>
                    },
                    {
                        name:      '현금영수증 별건 발행하기',
                        id:        'cash-receipt-publish',
                        component: <CashReceiptPublish id="cash-receipt-publish"/>
                    },
                    {
                        name:      '현금영수증 별건 취소하기',
                        id:        'cash-receipt-cancel',
                        component: <CashReceiptCancel id="cash-receipt-cancel"/>
                    }
                ]
            },
            {
                name:     'ENUM CODE',
                focus:    false,
                children: [
                    {
                        name:      'ENUM 값',
                        id:        'enum-introduce',
                        component: <EnumIntroduce id="enum-introduce"/>
                    },
                    {
                        name:      '결제',
                        id:        'enum-payment',
                        component: <EnumPayment id="enum-payment"/>
                    },
                    {
                        name:      '에스크로',
                        id:        'enum-escrow',
                        component: <EnumEscrow id="enum-escrow"/>
                    },
                    {
                        name:      '은행',
                        id:        'enum-bank',
                        component: <EnumBank id="enum-bank"/>
                    },
                    {
                        name:      '카드',
                        id:        'enum-card',
                        component: <EnumCard id="enum-card"/>
                    },
                    {
                        name:      '에러코드',
                        id:        'enum-error',
                        component: <EnumError id="enum-error"/>
                    }
                ]
            },
            // {
            //     name: '데이터 코드표',
            //     focus: false,
            //     children: [
            //         // 결제상태값
            //         // Response data
            //     ]
            // },
            // {
            //     name: '꼭 체크해야 할 사항',
            //     focus: false,
            //     children: [
            //         // 1. 결제 연동 시 (카드결제 테스트)
            //         // 2. 결제 연동 후  (웹훅통지)
            //         // 3. 가상계좌 연동시 (PG사마다 설정)
            //         // 4. 결제 취소시
            //         // 5. 서비스 오픈 시 (sandbox mode 해제했는지)
            //     ]
            // },
            // {
            //     name: '부트페이 TIP',
            //     focus: false,
            //     children: [
            //         // 1. 통합결제창 (복수 PG)
            //         // 2. 개발서버 / 실서버 따로 운영하기 (프로젝트를 생성)
            //         // 3. 모든 상황에서 결제가 되게 하기 위하여 결제창 선택처리
            //     ]
            // },
            // {
            //     name: 'Third Party',
            //     focus: false,
            //     children: [
            //         {
            //             name: 'Telegram',
            //             id: 'third-party-telegram',
            //             component: <ThirdPartyTelegram id="third-party-telegram"/>
            //         },
            //         {
            //             name: '원스토어',
            //             id: 'third-party-onestore',
            //             component: <ThirdPartyOnestore id="third-party-onestore"/>
            //         },
            //         {
            //             name: '결제 Webhook',
            //             id: 'third-party-webhook',
            //             component: <ThirdPartyWebhook id="third-party-webhook"/>
            //         }
            //     ]
            // }
        ]
        this.cursor = this.docs[0]
    }

    async componentDidMount() {
        this.setCurrentHashPosition()
        $(window).off(this.scrollEventName).on(this.scrollEventName, () => {
            this.calculateCurrentDocsCursor()
        })
        setTimeout(() => {
            Prism.highlightAll()
        }, 1000)
    }

    @action calculateCurrentDocsCursor() {
        if (this.hashChangeTimeoutInstance !== undefined) {
            clearTimeout(this.hashChangeTimeoutInstance)
        }
        const originScroll  = $(window).scrollTop()
        const currentScroll = originScroll > 0 ? originScroll + 5 : 5
        this.cursor         = undefined
        _.each(this.docs, (doc, i) => {
            const docNext = (this.docs.length === i + 1) ? undefined : this.docs[i + 1]
            const id      = $(`#${ this.$present(doc.children) ? doc.children.first().id : doc.id }`)
            const next    = this.$present(docNext) ? $(`#${ this.$present(docNext.children) ? docNext.children.first().id : docNext.id }`) : null
            if (this.$present(id) && this.$present(id.offset())) {
                const s = id.offset().top
                const e = (next === null || next.offset() === undefined ? $('#bootpay-main').height() : next.offset().top)
                if (doc.children !== undefined) {
                    _.each(doc.children, (child, _i) => {
                        const childNext  = doc.children.length === _i + 1 ? undefined : doc.children[_i + 1]
                        const childStart = $(`#${ child.id }`).offset().top
                        const childEnd   = this.$present(childNext) ? $(`#${ childNext.id }`).offset().top : e
                        if (childStart <= currentScroll && childEnd >= currentScroll) {
                            doc.focus                      = true
                            doc.selectFocus                = false
                            this.cursor                    = child
                            this.hashChangeTimeoutInstance = setTimeout(() => {
                                window.history.replaceState(null, null, `?front=${ this.$session.storageData.front }&backend=${ this.$session.storageData.backend }#${ child.id }`)
                            }, 500)
                            // location.hash = child.id
                        }
                    })
                } else {
                    if (s <= currentScroll && e > currentScroll) {
                        this.cursor                    = doc
                        doc.focus                      = true
                        this.hashChangeTimeoutInstance = setTimeout(() => {
                            window.history.replaceState(null, null, `?front=${ this.$session.storageData.front }&backend=${ this.$session.storageData.backend }#${ doc.id }`)
                        })
                    }
                }
            }
        })
        _.each(this.docs, (doc) => {
            doc.focus = this.isCurrentCursor(doc) || doc.selectFocus
        })
        this.forceUpdate()
    }

    isCurrentCursor(doc) {
        if (doc.children !== undefined) {
            return this.$present(this.cursor) && this.$present(
                _.filter(doc.children, (child) => child.id === this.cursor.id)
            )
        } else {
            return this.$present(this.cursor) && this.$present(doc.id) && this.cursor.id === doc.id
        }
    }

    setCurrentHashPosition() {
        const hash = String(location.hash).trim()
        if (this.$present(hash)) {
            // this.$showProgress('문서 내용을 찾는중입니다')
            // setTimeout(() => {
            this.$hideProgress()
            const element = $(hash)
            if (element.length) {
                this.forceUpdate(() => {
                    $(window).scrollTop(parseFloat($(element).offset().top))
                })
            }
            // }, 0)
        } else {
            this.forceUpdate()
        }
    }

    render() {
        return super.render({
            Fragment,
            location,
            DocsVersion,
            DocsSearch,
            BootSkeleton,
            Suspense
        })
    }
}