interface String {
    isEmail(): boolean

    toPhonePattern(): string

    toCardPattern(): string

    toComma(fixed: number): string

    zf(len: number): string

    string(len: number): string

    toCamelcase(): string

    toUnderscore(): string

    isIPFormat(): boolean

    isUrl(): boolean

    toCardNumber(): string
}

interface Number {
    toComma(fixed: number): string

    zf(len: number): string

    readableToPeople(): string

    readableToPrice(): string

    readableToCount(): string

    readableToFloatPrice(): string

    readableToShort(): string

    readableToFloat(): string

    toPercent(fixed): number

    toFixedNumber(fixed): number
}

interface Date {
    strftime(format: string): string

    minAgo(min: number): Date

    minAfter(min: number): Date

    hourAgo(hour: number): Date

    hourAfter(hour: number): Date

    dayAgo(day: number): Date

    dayAfter(day: number): Date

    diffDay(diffDate: Date): number

    monthAgo(month: number): Date

    monthAfter(month: number): Date

}

interface Array<T> {
    first(): any

    last(): any

    includes(value: any): boolean

    excludes(value: any): boolean
}

String.prototype.isEmail = function () {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return regex.test(this)
}

String.prototype.toPhonePattern = function () {
    try {
        return this.replace(/([0-9]{3})([0-9*]+)([0-9*]{4})/, "$1-$2-$3")
    } catch (e) {
        return this
    }
}

String.prototype.toCardPattern = function () {
    try {
        if (this.length > 12) {
            return this.replace(/([0-9]{4})([0-9]{4})([0-9]+)([0-9]{4})/, "$1-$2-$3-$4")
        } else {
            return this.replace(/([0-9]{4})([0-9]{4})([0-9]+)/, "$1-$2-$3")
        }
    } catch (e) {
        return this
    }
}

String.prototype.toComma = function (fixed = 0) {
    let number = this
    if (this.match(/\./g)) {
        number = parseFloat(this.replace(/,/g, ''))
    } else {
        number = parseInt(this.replace(/,/g, ''))
    }
    return isNaN(number) ? this : number.toComma(fixed)
}

String.prototype.string = function (len) {
    let t = ''
    let i = 0
    while (i++ < len) {
        t += this
        return t
    }
}

String.prototype.zf = function (len) {
    return this.length < 2 ? "0".string(len - this.length) + this : this
}

String.prototype.toCamelcase = function () {
    return this.replace(/(\_[a-z])/g, function ($1) {
        return $1.toUpperCase().replace(/_/g, '')
    })
}

String.prototype.toUnderscore = function () {
    return this.split(/(?=[A-Z])/).join('_').toLowerCase()
}

String.prototype.isIPFormat = function () {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this) || /^\d{1,3}(\.\d{1,3}){3}\/\d{1,2}$/.test(this)
}

String.prototype.isUrl = function () {
    return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/.test(this)
}

String.prototype.toCardNumber = function (splitter = '-') {
    if (this.length === 16) {
        return this.replace(/(.{4})(.{4})(.{4})(.{4})/, `$1${ splitter }$2${ splitter }$3${ splitter }$4`)
    } else if (this.length === 15) {
        return this.replace(/(.{4})(.{4})(.{4})(.{3})/, `$1${ splitter }$2${ splitter }$3${ splitter }$4`)
    } else {
        return this
    }
}

Number.prototype.zf = function (len) {
    return this.toString().zf(len)
}

Number.prototype.toComma = function (fixed = 0) {
    return this.toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


Date.prototype.strftime = function (format: string) {
    if (!this.valueOf()) {
        return " "
    } else {
        const weekPerDay: Array<string> = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
        const d = this
        return format.replace(/(%Y|%y|%m|%d|%e|%H|%h|%M|%S|%ap)/gi, ($1) => {
            switch ($1) {
                case '%Y':
                    return d.getFullYear()
                case '%y':
                    return (d.getFullYear() % 1000).zf(2)
                case '%m':
                    return (d.getMonth() + 1).zf(2)
                case '%d':
                    return (d.getDate()).zf(2)
                case '%e':
                    return weekPerDay[d.getDay()]
                case '%H':
                    return d.getHours().zf(2)
                case '%h':
                    return (d.getHours() % 12 ? d.getHours() % 12 : 12).zf(2)
                case '%M':
                    return d.getMinutes().zf(2)
                case '%S':
                    return d.getSeconds().zf(2)
                case '%ap':
                    return d.getHours() < 12 ? '오전' : '오후'
                default:
                    return $1
            }
        })
    }
}

Date.prototype.minAgo = function (min: number) {
    return new Date(this - 60000 * min)
}

Date.prototype.minAfter = function (min: number) {
    return this.minAgo(-min)
}

Date.prototype.hourAgo = function (hour: number) {
    return new Date(this - 3600000 * hour)
}

Date.prototype.hourAfter = function (hour: number) {
    return this.hourAgo(-hour)
}

Date.prototype.dayAgo = function (day: number) {
    const time = this - 86400000 * day
    return new Date(time)
}

Date.prototype.dayAfter = function (day: number) {
    return this.dayAgo(-day)
}

Date.prototype.diffDay = function (diffDate) {
    return Math.floor((this.getTime() - diffDate.getTime()) / 86400000)
}

Date.prototype.monthAgo = function (month: number, timezone = '+09:00') {
    const c = new Date(this.strftime(`%Y-%m-%dT%H:%M:%S${ timezone }`))
    c.setMonth(this.getMonth() - month)
    return c
}

Date.prototype.monthAfter = function (month: number, timezone = '+09:00') {
    return this.monthAgo(-month, timezone)
}

Array.prototype.first = function () {
    return this[0]
}

Array.prototype.last = function () {
    return this[this.length - 1]
}

if (typeof ([])['includes'] !== 'function') {
    Array.prototype.includes = function (value) {
        return this.indexOf(value) > -1
    }
}

Array.prototype.excludes = function (value) {
    return !this.includes(value)
}

Number.prototype.readableToPeople = function () {
    if (this < 100) {
        return `${ this.toComma(0) }명`
    } else if (this < 1000) {
        return (`${ Math.round((this / 100)) } 백명`)
    } else if (this < 10000) {
        return (`${ Math.round((this / 1000)).toComma(0) } 천명`)
    } else if (this < 1000000) {
        return (`${ Math.round((this / 10000)).toComma(0) } 만명`)
    } else if (this < 10000000) {
        return (`${ Math.round((this / 1000000)).toComma(0) } 백만명`)
    } else if (this < 100000000) {
        return (`${ Math.round((this / 10000000)).toComma(0) } 천만명`)
    } else {
        return `${ Math.round((this / 100000000)).toComma(0) } 억명`

    }
}

Number.prototype.readableToPrice = function () {
    if (this < 100) {
        return (`${ this.toComma(0) }원`)
    } else if (this < 1000) {
        return (`${ Math.round((this / 100)) } 백원`)
    } else if (this < 10000) {
        return (`${ Math.round((this / 1000)).toComma(0) } 천원`)
    } else if (this < 1000000) {
        return (`${ Math.round((this / 10000)).toComma(0) } 만원`)
    } else if (this < 10000000) {
        return (`${ Math.round((this / 1000000)).toComma(0) } 백만원`)
    } else if (this < 100000000) {
        return (`${ Math.round((this / 10000000)).toComma(0) } 천만원`)
    } else {
        return `${ Math.round((this / 100000000)).toComma(0) } 억원`

    }
}

Number.prototype.readableToCount = function () {
    if (this < 100) {
        return (`${ this.toComma(0) }건`)
    } else if (this < 1000) {
        return (`${ Math.round((this / 100)) } 백건`)
    } else if (this < 10000) {
        return (`${ Math.round((this / 1000)).toComma(0) } 천건`)
    } else if (this < 1000000) {
        return (`${ Math.round((this / 10000)).toComma(0) } 만건`)
    } else if (this < 10000000) {
        return (`${ Math.round((this / 1000000)).toComma(0) } 백만건`)
    } else if (this < 100000000) {
        return (`${ Math.round((this / 10000000)).toComma(0) } 천만건`)
    } else {
        return `${ Math.round((this / 100000000)).toComma(0) } 억건`
    }
}

Number.prototype.readableToFloatPrice = function () {
    if (this < 1000) {
        return (`${ this.toComma(0) }원`)
    } else if (this < 10000) {
        return (`${ Math.round((this / 1000.0)).toComma(2) } 천원`)
    } else if (this < 100000000) {
        return (`${ (this / 10000.0).toComma(2) } 만원`)
    } else {
        return `${ (this / 100000000.0).toComma(2) } 억원`
    }
}

Number.prototype.readableToShort = function () {
    if (this < 1000) {
        return this.toComma(0)
    } else if (this < 1000000) {
        return (`${ (this / 1000).toComma(0) }k`)
    } else if (this < 1000000000) {
        return (`${ (this / 1000000).toComma(0) }m`)
    } else {
        return `${ this / 1000000000 }b`
    }
}

Number.prototype.readableToFloat = function () {
    if (this < 1000) {
        return this.toComma(0)
    } else if (this < 1000000) {
        return (`${ (this / 1000.0).toComma(2) }k`)
    } else if (this < 1000000000) {
        return (`${ (this / 1000000.0).toComma(2) }m`)
    } else {
        return `${ (this / 1000000000.0).toComma(2) }b`
    }
}

Number.prototype.toPercent = function (fixed) {
    return parseFloat((this * 100).toFixed(fixed))
}

Number.prototype.toFixedNumber = function (fixed) {
    let pow = Math.pow(10, fixed)
    return Math.round(this * pow) / pow
}
