import { BootpayComponent } from 'components/bootpay'
import { inject, observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import $ from 'jquery'
import _ from 'lodash'

@inject('store')
@observer
export class DocsSearch extends BootpayComponent {
    @observable isShow: boolean
    @observable searchResult: Array<any>
    @observable keyword: string
    escapeEvent = 'keyup.background-search'
    inputRef
    timeoutRef

    constructor(props) {
        super(props)
        this.$template = require('search')
        this.isShow = false
        this.keyword = ''
    }

    componentDidMount() {
    }

    @computed get docs() {
        return this.props.docs
    }

    show() {
        $(document).off(this.escapeEvent).on(this.escapeEvent, (e) => {
            if (e.key === 'Escape') {
                return this.hide()
            }
        })
        this.keyword = ''
        this.searchResult = []
        this.isShow = true
        this.forceUpdate(() => {
            this.inputRef.focus()
        })
    }

    hide() {
        $(document).off(this.escapeEvent)
        this.isShow = false
        this.forceUpdate()
    }

    /**
     * document 찾기 시작
     * Comment by Gosomi
     * @date: 2022-05-30
     */
    searchDocument(keyword) {
        this.keyword = keyword
        if (this.$present(this.timeoutRef)) {
            clearTimeout(this.timeoutRef)
        }
        this.timeoutRef = setTimeout(() => {
            const result = []
            if (keyword !== undefined && keyword.trim().length > 0) {
                const regex = new RegExp(keyword, 'gi')
                _.each(this.docs, (doc) => {
                    if (this.$present(doc.children)) {
                        _.each(doc.children, (child) => {
                            const html = String($(`#${ child.id } > .section-row > .content`).text()).replace("\n", '')
                            if (html !== undefined) {
                                let key
                                while ((key = regex.exec(html)) !== null) {
                                    const content = html.substring(key.index, key.index + 100)
                                    result.push({
                                        title: `${ doc.name } > ${ child.name }`,
                                        content: content.replace(/ /gi, '&nbsp;'),
                                        id: child.id
                                    })
                                }
                            }
                        })
                    } else {
                        const html = String($(`#${ doc.id } > .section-row > .content`).text()).replace("\n", '')
                        if (html !== undefined) {
                            let key
                            while ((key = regex.exec(html)) !== null) {
                                const content = html.substring(key.index, key.index + 100)
                                result.push({
                                    title: `${ doc.name }`,
                                    content: content.replace(/ /gi, '&nbsp;'),
                                    id: doc.id
                                })
                            }
                        }
                    }
                })
            }
            this.searchResult = result
            this.forceUpdate()
        }, 300)
        this.forceUpdate()
    }

    goDocs(result) {
        location.hash = result.id
        this.hide()
    }

    render() {
        return super.render({})
    }
}