import _ from 'lodash'
import { isObservable, isObservableArray } from 'mobx'
export interface Validate {
    isBlank(value: any): boolean

    isPresent(value: any): boolean

    presence(value: any, defaultValue: any): any

    isType(value: any, type: string): boolean
}

const ValidClass: Validate = {
    isBlank(value: any): boolean {
        let valid: boolean = false
        if (typeof value === 'string') {
            valid = value.length === 0
        } else if (Array.isArray(value) || isObservableArray(value)) {
            valid = value.length === 0
        } else {
            valid = value === undefined ||
                value === null ||
                (this.isType(value, 'object') && value.constructor === Object && _.keys(value).length === 0)
        }
        return valid
    },

    isPresent(value: any): boolean {
        return !this.isBlank(value)
    },

    presence(value: any, defaultValue: any): any {
        if (this.isBlank(value)) {
            return defaultValue
        } else {
            return value
        }
    },

    isType(value: any, type: string) {
        return (typeof value === type)
    }
}

export { ValidClass }
