import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64'
import { isPresent, isType } from 'support-js/support'
import $ from 'jquery'
import { generateUUID } from 'support-js/support'
import { BootpayDeviceUUID } from 'support-js/mixins/device-uuid'
import _ from 'lodash'
import { isProgress, showProgress, hideProgress } from 'support-js/react/components/boot-progress'
// @ts-ignore
import { BootpayBaseUrl } from 'stores/env.ts.erb'

interface EncryptParamsFormat {
    payload: string
    token: string
}

class ReactResource {
    static httpStatic: AxiosInstance
    static onHide: boolean = true
    $http: AxiosInstance
    LockKeys: Array<string>


    constructor() {
        this.$http    = ReactResource.httpStatic
        this.LockKeys = []
    }

    /**
     * 기본 URL로 변경
     * Comment by Gosomi
     * @date: 2022-01-13
     */
    toHostUrl(url: string) {
        return `${ BootpayBaseUrl }/${ url }`
    }

    /**
     * Header를 생성한다
     * Comment by Gosomi
     * @date: 2022-02-09
     */
    setHeader(key: string, value: any) {
        this.$http.defaults.headers.common[key] = value
    }

    static axiosBootpayConfigure() {
        ReactResource.httpStatic                                                = axios.create()
        // Class 내 construct 에서 여러번 호출될 수 있으므로 한번만 호출되도록 한다
        ReactResource.httpStatic.defaults.headers.common['X-CSRF-Token']        = $('meta[name="csrf-token"]').attr('content')
        ReactResource.httpStatic.defaults.headers.common['Content-Type']        = 'application/json'
        ReactResource.httpStatic.defaults.headers.common['Accept']              = 'application/json'
        ReactResource.httpStatic.defaults.headers.common['Bootpay-Device-UUID'] = BootpayDeviceUUID.getDeviceId()
        ReactResource.httpStatic.defaults.withCredentials                       = true
        ReactResource.httpStatic.interceptors.request.use((config: AxiosRequestConfig) => {
            // 강제로 Device ID 씌움
            config.headers['Bootpay-Device-UUID'] = BootpayDeviceUUID.getDeviceId()
            if (isPresent(config.data), this.isHttpBodyUsedMethod(config.method), config.headers['Content-Type'] != 'multipart/form-data') {
                config.data = this.encryptParams(config.data)
            }
            ReactResource.onHide = config.headers.onHide !== undefined ? config.headers.onHide as boolean : true
            // 프로그래스창이 메세지가 있다면 보여준다
            if (isPresent(config.headers.progress)) {
                showProgress(config.headers.progress as string)
                delete config.headers.progress
            }
            // Do something before request is sent
            return config
        }, (error) => {
            // Do something with request error
            return Promise.reject(error)
        })

        ReactResource.httpStatic.interceptors.response.use((response: AxiosResponse): any => {
            // 프로그래스창이 있다면 바로 삭제한다
            if (isProgress() && ReactResource.onHide) {
                hideProgress()
            }
            return response
        }, function (error) {
            if (isProgress() && ReactResource.onHide) {
                hideProgress()
            }
            if (isPresent(error.response)) {
                const error_data = error.response.data
                switch(error_data.error_code){
                    case 'MEMBER_SESSION_INVALID':
                        return location.href = "/login"
                    default:
                }
                const responseData = _.merge({
                    status: error.response.status
                }, error_data)
                return Promise.reject(responseData)
            } else {
                return Promise.reject(error)
            }
        })
    }

    static isHttpBodyUsedMethod(method): boolean {
        return ['post', 'put'].indexOf(method) > -1
    }

    static encryptParams(data: Object): EncryptParamsFormat {
        if (isType(data, 'object')) {
            data = JSON.stringify(data)
        }
        let encryptData = AES.encrypt(data, generateUUID())
        return {
            payload: encryptData.ciphertext.toString(Base64),
            token:   `${ encryptData.key.toString(Base64) }##${ encryptData.iv.toString(Base64) }`
        } as EncryptParamsFormat
    }

    async semaphoreExecute(key: string, block: Function) {
        if (this.LockKeys.indexOf(key) > -1) {
            return Promise.reject({
                code:    10,
                message: '중복 작업중입니다.'
            })

        } else {
            this.LockKeys.push(key)
            try {
                const response = await block()
                this.releaseSemaphore(key)
                return Promise.resolve(response)
            } catch (e) {
                this.releaseSemaphore(key)
                return Promise.reject(e)
            }
        }
    }

    releaseSemaphore(key: string) {
        const index = this.LockKeys.indexOf(key)
        return index > -1 ? this.LockKeys.splice(index, 1) : undefined
    }

    static objectKeyToUnderscore(obj: any): any {
        let cloneObject: any = undefined
        if (isPresent(obj)) {
            if (Array.isArray(obj)) {
                cloneObject = []
                _.each(obj, (child) => {
                    let childObject: any = {}
                    _.each(child, (value, key) => {
                        childObject[key.toUnderscore()] = value
                    })
                    cloneObject.push(childObject)
                })
            } else {
                cloneObject = {}
                _.each(obj, (value, key) => {
                    cloneObject[key.toUnderscore()] = value
                })
            }
        }
        return cloneObject
    }

    static objectKeyToCamel(obj: any): any {
        let cloneObject: any = undefined
        if (isPresent(obj)) {
            if (Array.isArray(obj)) {
                cloneObject = []
                _.each(obj, (child) => {
                    let childObject: any = {}
                    _.each(child, (value, key) => {
                        childObject[key.toCamelcase()] = value
                    })
                    cloneObject.push(childObject)
                })
            } else {
                cloneObject = {}
                _.each(obj, (value, key) => {
                    if (isType(value, 'object') && _.keys(value).length > 0) {
                        cloneObject[key.toCamelcase()] = this.objectKeyToCamel(value)
                    } else {
                        cloneObject[key.toCamelcase()] = value
                    }
                })
            }
        }
        return cloneObject
    }
}

// 한번만 호출한다 static 함수로 ( import를 여러번 하더라도 호출을 여러번 안함 )
ReactResource.axiosBootpayConfigure()

export { ReactResource }