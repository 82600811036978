import { action, computed, makeObservable, observable } from 'mobx'
import { isPresent } from 'support-js/support'
import Utf8 from 'crypto-js/enc-utf8'
import Base64 from 'crypto-js/enc-base64'
import { ReactResource } from 'support-js/react/resources'
import _ from 'lodash'
import $ from 'jquery'

export class SessionStore extends ReactResource {
    @observable storageData
    storage
    storageKey: string
    encryptKey: string

    constructor() {
        super()
        makeObservable(this)
        this.storage = {}
        this.storageKey = '__BOOTPAY_DOCS_STORAGE'
        this.encryptKey = '__BOOTPAY_DOCS_ENCRYPT_KEY'
        this.storageData = this.decryptStorageData()
        this.sdkVersion()
    }

    @action updateFrontLanguage(language: string) {
        this.storageData.front = language
        this.updateStorage()
    }

    @action updateBackendLanguage(language: string) {
        this.storageData.backend = language
        this.updateStorage()
    }


    @action updatePg(pg: string) {
        this.storageData.pg = pg
        this.updateStorage()
    }

    @action updateSDKVersion(version: string) {
        this.storageData.version = version
        this.updateStorage()
    }

    @action
    async sdkVersion() {
        try {
            const response = await this.$http.get(this.toHostUrl('version.json'), {
                headers: {
                    onHide: false
                }
            })
            return this.updateSDKVersion(response.data.version)
        } catch (e) {
            this.updateSDKVersion('4.0.0')
        }
    }

    isFront(language: string) {
        const index = this.storageData.front.indexOf('-') //android-java to android
        return index == -1 ? this.storageData.front === language : this.storageData.front.substring(0, index) === language
    }

    isBackend(language: string) {
        const index = this.storageData.backend.indexOf('-') //java-gradle to java
        return index == -1 ? this.storageData.backend === language : this.storageData.backend.substring(0, index) === language
    }


    isNotIncludesFront(languages: [string]) {
        const exist = _.filter(languages, (v) => this.isFront(v)).first()
        return exist === undefined
    }

    isPg(pg: String) {
        return this.storageData.pg === pg
    }

    defaultValue() {
        return { backend: 'curl', front: 'web', version: '4.0.0', pg: 'kcp' }
    }

    @computed get frontLanguage() {
        return this.storageData.front
        // const index = this.storageData.front.indexOf('-') //android-java to android
        // return index == -1 ? this.storageData.front : this.storageData.front.substring(0, index)
    }

    @computed get backendLanguage() {
        return this.storageData.backend
    }

    @computed get backendPg() {
        return this.storageData.pg
    }

    @computed get SDKVersion() {
        switch (this.frontLanguage) {
            case 'web':
                return this.storageData.version
            default:
                return this.storageData.version
        }
    }

    decryptStorageData() {
        const data = this.get(this.storageKey)
        if (isPresent(data)) {
            return JSON.parse(
                Base64.parse(data).toString(Utf8)
            )
        } else {
            this.set(this.storageKey, Base64.stringify(Utf8.parse(JSON.stringify(this.defaultValue()))))
            return this.decryptStorageData()
        }
    }

    @action updateStorage() {
        this.set(this.storageKey, Base64.stringify(Utf8.parse(JSON.stringify(this.storageData))))
    }

    get(key: string) {
        try {
            return window.localStorage.getItem(key)
        } catch (e) {
            return this.storage[key]
        }
    }

    set(key: string, value: string) {
        try {
            window.localStorage.setItem(key, value)
        } catch (e) {
            this.storage[key] = value
        }
    }
}

export const Session = new SessionStore()