import { ValidClass } from './validate'
import { generateUUID } from 'support-js/support/utils'

const presence = (value: any, defaultValue: any): any => {
    return ValidClass.presence(value, defaultValue)
}

const presenceNumber = (value: any, defaultValue: number): any => {
    const returnValue = parseInt(ValidClass.presence(value, defaultValue))
    return isNaN(returnValue) ? defaultValue : returnValue
}

const isPresent = (value: any): Boolean => {
    return ValidClass.isPresent(value)
}

const isBlank = (value: any): Boolean => {
    return ValidClass.isBlank(value)
}

const isType = (value: any, type: String): Boolean => {
    return (typeof value === type)
}

export { presence, presenceNumber, isPresent, isBlank, isType, generateUUID }
