import React, { RefObject } from 'react'
import 'support-js/react/components/boot-alert/style.sass'
import { isPresent } from 'support-js/support'

export class BootAlert extends React.Component<any, any> {
    hideTimeoutInstance = undefined

    constructor(props) {
        super(props)
        this.state = {
            animateShow: false,
            isShow: false,
            title: '' || '확인',
            message: '',
            cancelText: '' || '취소',
            confirmText: '' || '확인',
            confirm: false,
            resolve: undefined,
            reject: undefined
        }
    }

    template() {
        return {
            __html: this.state.message
        }
    }

    show(message: string, title: string = '알림', confirmText: string = '확인') {
        if(this.hideTimeoutInstance  !== undefined) {
            clearTimeout(this.hideTimeoutInstance)
        }
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                document.getElementById('boot-alert-success-button').focus()
            }, 200)
            this.setState({
                animateShow: true,
                isShow: true,
                message: message,
                title: title,
                resolve: resolve,
                reject: reject,
                confirm: false,
                confirmText: confirmText || '확인'
            })
        })
    }

    confirm(message: string, title: string = '확인', confirmText: string = '확인') {
        if(this.hideTimeoutInstance  !== undefined) {
            clearTimeout(this.hideTimeoutInstance)
        }
        return new Promise((resolve, reject) => {
            this.setState({
                animateShow: true,
                isShow: true,
                message: message,
                title: title,
                resolve: resolve,
                reject: reject,
                confirm: true,
                confirmText: confirmText || '확인'
            })
        })
    }

    hide() {
        this.setState({
            animateShow: false
        })
        this.hideTimeoutInstance = setTimeout(() => {
            this.setState({
                isShow: false
            })
        }, 100)
    }

    doCancel(e) {
        e.preventDefault()
        this.hide()
        this.state.reject(new AlertException())
    }

    doSuccess(e) {
        e.preventDefault()
        this.hide()
        this.state.resolve()
    }

    render() {
        return this.state.isShow && (
            <div className="boot-alert">
                <div
                    className={ `alert-inner animate__animated animate__fadeIn animate__faster ${ this.state.animateShow ? 'raise' : 'smaller' }` }>
                    <div className="header">
                        <h3 className="alert-title">{ this.state.title }</h3>
                    </div>
                    <div className="body" dangerouslySetInnerHTML={ this.template() }/>
                    <div className="footer">
                        { this.state.confirm ?
                            <div className="button-if layout-row">
                                <a href="" className="alert-button cancel-btn half-alert-button"
                                   onClick={ (e) => this.doCancel(e) }>{ this.state.cancelText }</a>
                                <a href="" id="boot-alert-success-button"
                                   className="alert-button success-btn half-alert-button bootpay-color bootpay-theme-color"
                                   onClick={ (e) => this.doSuccess(e) }>{ this.state.confirmText }</a>
                            </div> :
                            <div className="button-if layout-xs-row">
                                <a href="" id="boot-alert-success-button"
                                   className="alert-button success-btn full-alert-button bootpay-color bootpay-theme-color"
                                   onClick={ (e) => this.doSuccess(e) }>{ this.state.confirmText }</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export class AlertException {
    type: string
    message: string

    constructor() {
        this.type = this.constructor.name
        this.message = ""
    }

}

export const bootAlertRef: RefObject<BootAlert> = React.createRef()

export const showBootAlert = (message: string, title: string = '알림', confirmText: string = '확인') => {
    return isPresent(bootAlertRef.current) ? bootAlertRef.current.show(message, title, confirmText) : null
}

export const showBootConfirm = (message: string, title: string = '확인', confirmText: string = '확인') => {
    return isPresent(bootAlertRef.current) ? bootAlertRef.current.confirm(message, title, confirmText) : null
}