import * as React from 'react'
import { isPresent } from 'support-js/support'
// @ts-ignore
import { ConstStore } from 'stores/const.ts.erb'
import { TemplateView } from 'stores/template-view'
// @ts-ignore
import { AssetImage } from 'stores/image.ts.erb'
import _ from 'lodash'
import { SessionStore } from 'stores/session'
import { showBootAlert, showBootConfirm } from 'support-js/react/components/boot-alert'
import { BootProgressOption, hideProgress, showProgress } from 'support-js/react/components/boot-progress'
import $ from 'jquery'
import { RefObject } from 'react'
import { computed } from 'mobx'
import { generateUUID } from 'support-js/support'
import { createBrowserHistory } from 'history'
import { BootpayStorageStore } from 'stores/storage'

export class ReactComponent<P, S> extends React.Component<any, any> {
    $template: any
    $resource: any
    $const: ConstStore
    $session: SessionStore
    $storage: BootpayStorageStore
    $history: any
    $root: RefObject<any>
    ticks

    constructor(props) {
        super(props)
        if (this.$present(props.store)) {
            const { constant, session, storage } = props.store
            this.$const                          = constant
            this.$session                        = session
            this.$storage                        = storage
            this.$history                        = this.$present(props.history) ? props.history : createBrowserHistory()
        }
        this.ticks = {}
    }

    /**
     * history change event 를 만든다
     * Comment by Gosomi
     * @date: 2021-05-03
     * @param prevProps: Readonly<any>
     * @param prevState: Readonly<any>
     * @param snapshot?: any
     */
    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.$present(this.props.location) && (
            this.props.location.pathname !== prevProps.location.pathname ||
            this.props.location.search !== prevProps.location.search ||
            this.props.location.hash !== prevProps.location.hash)
        ) {
            this.componentHistoryChange(prevProps, prevState, snapshot)
        }
        let tooltipElements = $('[data-toggle="tooltip"]')
        if (tooltipElements.length > 0) {
            tooltipElements.on("mouseenter", (e) => {
                e.preventDefault()
                $(e.currentTarget).tooltip('show')
            })
        }
        this.executeTicks()
        return this.componentChildDidUpdate(prevProps, prevState, snapshot)
    }

    componentChildDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        return
    }

    //보류
    initHistory() {
        if(this.$present(this.props.history)){
            return this.props.history
        }else if(this.$present(this.$session.getHistory())) {
            return this.$session.getHistory()
        }else {
            const history = createBrowserHistory()
            this.$session.setHistory(history)
            return history
        }
    }

    '$nextTick'(tickFunction) {
        const uuid       = generateUUID()
        const timeout    = setTimeout(() => {
            this.executeTicks()
        }, 100)
        this.ticks[uuid] = {
            timeout,
            tickFunction
        }
    }

    executeTicks() {
        _.each(this.ticks, (tick, key) => {
            clearTimeout(tick.timeout)
            tick.tickFunction.call(this)
            delete this.ticks[key]
        })
    }

    /**
     * Component History Change 이벤트 원함수
     * Comment by Gosomi
     * @date: 2021-05-03
     */
    componentHistoryChange(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    }

    /**
     * alert 창을 보여준다
     * Comment by GOSOMI
     * @date: 2021-04-05
     * @param message: string
     * @param title: string
     * @param confirmText: string
     */
    '$alert'(message: string, title: string = '알림', confirmText: string = '확인') {
        return showBootAlert(message, title, confirmText)
    }

    /**
     * Confirm 창
     * Comment by Gosomi
     * @date: 2021-05-01성
     * @param message: string
     * @param title: string
     * @param confirmText: string
     */
    '$confirm'(message: string, title: string = '확인', confirmText: string = '확인') {
        return showBootConfirm(message, title, confirmText)
    }

    /**
     * Progress 창 보여주기
     * Comment by Gosomi
     * @date: 2021-05-01
     * @param message: string
     * @param option: BootProgressOption
     */
    '$showProgress'(message: string, option: BootProgressOption = {}) {
        return showProgress(message, option)
    }

    /**
     * Progress창 닫기
     * Comment by Gosomi
     * @date: 2021-05-01
     */
    '$hideProgress'() {
        return hideProgress()
    }

    /**
     * 값이 present
     * Comment by GOSOMI
     * @date: 2021-04-05
     * @param value: any
     * @returns boolean
     */
    '$present'(value: any) {
        return isPresent(value)
    }

    /**
     * 값이 Blank
     * Comment by GOSOMI
     * @date: 2021-04-05
     * @param value: any
     * @returns boolean
     */
    '$blank'(value: any) {
        return !this.$present(value)
    }

    /**
     * Presence
     * Comment by Gosomi
     * @date: 2021-05-07
     * @param value: any
     * @param defaultValue: any
     * @returns any
     */
    '$presence'(value: any, defaultValue: any) {
        return this.$present(value) ? value : defaultValue
    }

    /**
     * Step Presence
     * 필요한 값으로 접근하는 경로의 모든 null 인 가능성을 판단하다
     *
     * 예:
     * ```
     * const person = {
     *     pocket = {
     *          wallet: {
     *              money: 0
     *          },
     *     },
     * }
     * $step_presence( person, 'poor-man', 'pocket','wallet','money')
     * => 0
     * $step_presence( person, 'poor-man', 'girl_friend','name')
     * => 'poor-man'
     *
     * ```
     * Comment by Alfred
     * @date 2022/01/11 10:15 AM
     * @param confirmed 해당경로 부터 검사
     * @param defaultValue 경로중 null 인값이 있을 시 리턴
     * @param steps null 인 가능성 있는 경로들.
     * @return value
     */
    '$step_presence'(confirmed: any, defaultValue: any, ...steps: Array<string>): any {
        return steps.reduce((stepValue, curStep) => {
            if (this.$blank(stepValue)) {
                return defaultValue
            }
            stepValue = stepValue[curStep]
            if (this.$blank(stepValue)) {
                return defaultValue
            }
            return stepValue
        }, confirmed)
    }

    /**
     * Parent 접근
     * Comment by Gosomi
     * @date: 2021-05-04
     */
    @computed
    get '$parent'() {
        if (this.$blank(this.props.parentRef)) {
            console.warn('parentRef value undefined')
            return undefined
        } else {
            return this.props.parentRef
        }
    }

    /**
     * 값을 갱신
     * Comment by Gosomi
     * @date: 2021-04-28
     * @param key: string
     * @param value: any
     */
    setValue(key: string, value: any) {
        this[key] = value
    }

    /**
     * 사용할 리소스를 설정한다
     * Comment by Gosomi
     * @date: 2021-04-28
     * @param resource: any
     */
    setResource<T>(resource: T) {
        this.$resource = resource as T
    }

    /**
     * 템플릿 설정
     * Comment by Gosomi
     * @date: 2021-04-29
     */
    setTemplateView(side: boolean, header: boolean) {
        TemplateView.showSide(side)
        TemplateView.showHeader(header)
    }

    /**
     * 이미지 실제경로
     * @comment by Alfred feat. Gosomi
     * @date 2021-04-30, 11:05
     * @param path[string]
     * @returns absPath[string]
     */
    getImagePath(path: string): string {
        let imagePath = ''
        if (/\./.test(path)) {
            imagePath = AssetImage[path]
        } else {
            _.each(['jpg', 'png', 'gif', 'jpeg', 'svg'], (v) => {
                if (this.$present(AssetImage[`${ path }.${ v }`])) {
                    imagePath = AssetImage[`${ path }.${ v }`]
                    return false
                }
            })
        }
        return imagePath
    }

    /**
     * uri query 리턴
     * Comment by Gosomi
     * @date: 2021-04-30
     * @param key: string
     * @param defaultValue: any
     * @returns string
     */
    getQuery(key: string, defaultValue: any = undefined) {
        try {
            const query = new URLSearchParams(this.props.location.search)
            return this.$presence(query.get(key), defaultValue)
        } catch (e) {
            return defaultValue
        }
    }

    /**
     * url 에서 query 를 객체형태로 리턴
     * @comment by Alfred
     * @date 2021-05-21, 18:09
     */
    getQueryObj(): any {
        const urlParams   = new URLSearchParams(this.props.location.search)
        const keys        = this.props.location.search.substring(1).split("&").map((queryStr) => {
            return (queryStr.split("=")[0])
        })
        const result: any = {}
        keys.forEach((key) => {
            if (this.$present(key)) {
                result[key] = urlParams.get(key)
            }
        })
        return result
    }

    setQuery(key: string | Array<string>, value: any = null) {
        if (typeof key == 'string') {
            key   = [key]
            value = [value]
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        const keys      = this.props.location.search.substring(1).split("&").map((queryStr) => {
            return (queryStr.split("=")[0])
        })

        let queryList = keys.filter((oldKey) => {
            return oldKey.length > 0 && !key.includes(oldKey) && oldKey != 'page'
        }).map((oldKey) => {
            return `${ oldKey }=${ urlParams.get(oldKey) }`
        })

        key.forEach((k, kIndex) => {
            if (![null, undefined, ""].includes(value[kIndex])) {
                queryList.push(`${ k }=${ value[kIndex] }`)
            }
        })
        let url = queryList.length > 0 ? `${ this.$history.location.pathname }?${ queryList.join("&") }` : this.$history.location.pathname
        return this.$history.push(url)
    }

    /**
     * 입력되 변수들만 query 에 설정,  기타값 삭제
     * Comment by Alfred
     * @date 2022/01/11 6:15 PM
     * @param key
     * @param value
     * @return void
     */
    forceQuery(key: string | Array<string>, value: any = null) {
        if (typeof key == 'string') {
            key   = [key]
            value = [value]
        }
        let queryList = []
        key.forEach((k, kIndex) => {
            if (![null, undefined, ""].includes(value[kIndex])) {
                queryList.push(`${ k }=${ value[kIndex] }`)
            }
        })
        let url = queryList.length > 0 ? `${ this.$history.location.pathname }?${ queryList.join("&") }` : this.$history.location.pathname
        return this.$history.push(url)
    }

    toQuery(query = {}) {
        return `?${ new URLSearchParams(query).toString() }`
    }

    clearQuery() {
        return this.$history.push(this.$history.location.pathname)
    }

    /**
     * 모달창을 닫는다
     * Comment by Gosomi
     * @date: 2021-05-03
     */
    hideModal() {
        this.$present(this.$root.current) ? $(this.$root.current).modal('hide') : $(this.$root).modal('hide')
    }

    /**
     * 모달창을 연다
     * Comment by Gosomi
     * @date: 2021-05-03
     */
    showModal() {
        if (this.$present(this.$root)) {
            const modal = this.$present(this.$root.current) ? this.$root.current : this.$root
            $(modal).modal({
                backdrop: 'static'
            })
            $(modal).modal('show')
        }
    }

    isMobile() {
        const a = (window.navigator.userAgent || window.navigator.vendor)
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
    }

    isMobileSafari() {
        const agent = window.navigator.userAgent
        return (agent.match(/iPad/i) || agent.match(/iPhone/i)) && !agent.match(/CriOS/i)
    }

    /* 가맹점 레벨 별로  홈페이지 가 다름 | comment by Alfred | date 2021/06/25 */
    goHome() {
        location.href = '/'
    }

    goLogin() {
        location.href = '/login'
    }

    '$navigate'(url: string) {
        return this.$history.push(url)
    }

    render(params = {}) {
        return this.$template.default.call(this, params)
    }
}