import React, { Fragment } from 'react';

export default function() {
  return (
    this.isShow && (
      <Fragment>
        <div className="search-background">
          <div
            className="close-background-transparent"
            onClick={() => {
              return this.hide();
            }}
          ></div>
          <div className="search-container">
            <div className="search-bar">
              <div className="inner-search-bar">
                <div className="row m-0 p-0 align-self-center align-items-center">
                  <div className="col-auto m-0 p-0">
                    <div className="px-1">
                      <i className="mdi mdi-magnify"></i>
                    </div>
                  </div>
                  <div className="col m-0 p-0">
                    <input
                      className="search-input"
                      placeholder="검색어를 입력해주세요"
                      ref={ref => {
                        this.inputRef = ref;
                      }}
                      onChange={e => {
                        return this.searchDocument(e.currentTarget.value);
                      }}
                      value={this.keyword}
                    />
                  </div>
                  <div className="col-auto m-0 p-0">
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        this.keyword = '';
                        this.forceUpdate();
                      }}
                    >
                      <i className="mdi mdi-close-circle text-secondary"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="result">
              {this.$blank(this.keyword) && (
                <Fragment>
                  <div className="p-3">
                    <span className="text-light-grey">문서내에서 검색할 단어를 입력해주세요</span>
                  </div>
                </Fragment>
              )}
              {this.$present(this.keyword) && (
                <Fragment>
                  <ul className="docs-search-result">
                    <li className="header">
                      총 {this.$present(this.searchResult) ? this.searchResult.length.toComma() : 0}건
                    </li>
                    {(this.$blank(this.searchResult) || this.searchResult.length === 0) && (
                      <Fragment>
                        <li className="text-center">
                          <div className="p-3">검색된 내용이 없습니다</div>
                        </li>
                      </Fragment>
                    )}
                    {this.$present(this.searchResult) && (
                      <Fragment>
                        {(this.searchResult || []).map((result, i) => (
                          <li key={i}>
                            <a
                              href=""
                              onClick={e => {
                                e.preventDefault();
                                return this.goDocs(result);
                              }}
                            >
                              <div className="title">{result.title}</div>
                              <div className="content mt-3" dangerouslySetInnerHTML={{ __html: result.content }}></div>
                            </a>
                          </li>
                        ))}
                      </Fragment>
                    )}
                  </ul>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    )
  );
}
