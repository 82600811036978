import { BootpayRoutes } from 'bootpay-routes'
import React from 'react'
import { BootAlert, bootAlertRef } from 'support-js/react/components/boot-alert'
import { observer, Provider } from 'mobx-react'
import { RootStore } from 'stores/root'
import { BrowserRouter } from 'react-router-dom'
import { BootProgress, bootProgressRef } from 'support-js/react/components/boot-progress'
// @ts-ignore
import { BootpayBaseUrl } from 'stores/env.ts.erb'

export const App = observer((props) => {
    return (
        <Provider store={ RootStore }>
            <BootAlert ref={ bootAlertRef }/>
            <BootProgress ref={ bootProgressRef }/>
            <BrowserRouter basename={ BootpayBaseUrl }>
                <div id="bootpay-main">
                    <BootpayRoutes/>
                </div>
            </BrowserRouter>
        </Provider>
    )
})