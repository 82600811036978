import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Main } from 'components/main'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const BootpayRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={ withComponent(Main) }/>
        </Routes>
    )
}

export const withComponent = (Component) => {
    const WrapperComponent = (props) => {
        const defaultAttrs = {
            location: useLocation(),
            navigate: useNavigate(),
            params: useParams()
        }
        return <Component { ...props } { ...defaultAttrs } />
    }
    return <WrapperComponent/>
}