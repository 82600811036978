import { generateUUID, isBlank, presence } from 'support-js/support'

declare global {
    interface Window {
        BootpayStorage: any
    }
}

const BootpayDeviceUUID = {
    DEVICE_KEY: '__BOOTPAY_DEVICE_UUID__',
    localStorage: {},
    getDeviceId() {
        let deviceId = this.getDeviceIdByStorage()
        if (isBlank(deviceId)) {
            try {
                deviceId = this.updateDeviceId(window.localStorage.getItem('undefined'))
            } catch (e) {
                deviceId = this.generateDeviceId()
            }
        }
        return deviceId
    },
    /**
     * Device ID 가져오기
     * Comment by Gosomi
     * @date: 2022-02-22
     */
    getDeviceIdByStorage() {
        try {
            return window.localStorage.getItem(this.DEVICE_KEY)
        } catch (e) {
            return this.getGlobal(this.DEVICE_KEY)
        }
    },
    /**
     * Device UUID Update
     * Comment by Gosomi
     * @date: 2022-02-22
     */
    updateDeviceId(uuid: string) {
        const deviceId = presence(uuid, this.generateDeviceId())
        try {
            window.localStorage.setItem(this.DEVICE_KEY, deviceId)
        } catch (e) {
            console.log(`set global ${ deviceId }`)
            this.setGlobal(this.DEVICE_KEY, deviceId)
        }
        return deviceId
    },
    /**
     * Generate Device ID
     * Comment by Gosomi
     * @date: 2022-02-22
     */
    generateDeviceId() {
        return `${ generateUUID() }-${ (new Date()).getTime() }`
    },

    setGlobal(key: string, value: any) {
        if (isBlank(window.BootpayStorage)) {
            window.BootpayStorage = {}
        }
        window.BootpayStorage[key] = value
    },

    getGlobal(key: string) {
        return isBlank(window.BootpayStorage) ? undefined : window.BootpayStorage[key]
    }
}

export { BootpayDeviceUUID }