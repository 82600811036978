// @ts-ignore

import { Session, SessionStore } from 'stores/session'

export interface RootStoreInterface {
    session: SessionStore
}

export const RootStore = {
    session: Session
} as RootStoreInterface