import React, { Fragment } from 'react';

export default function(__params = {}) {
  const { DocsSearch, DocsVersion, undefined } = __params;
  return (
    <main className="bootpay-main light">
      <aside className="bootpay-side">
        <h3 className="site-title">
          <a href="">
            <img className="w-100px" src={this.getImagePath('bootpay/bootpay-logo.svg')} />
            <span className="badge bg-primary sub-title">Docs</span>
          </a>
        </h3>
        <ul className="bootpay-menu">
          <li className="search">
            <button
              className="search-button"
              onClick={() => {
                this.searchRef.show();
              }}
            >
              <i className="mdi mdi-search-web text-dark"></i>
              <span className="ms-2">검색하기</span>
            </button>
          </li>
          {(this.docs || []).map((doc, i) => (
            <Fragment key={i}>
              {this.$blank(doc.children) && (
                <Fragment>
                  <li className={`${this.isCurrentCursor(doc) ? 'active' : ''}`}>
                    <a href={`#${doc.id}`}>
                      <span dangerouslySetInnerHTML={{ __html: doc.name }}></span>
                    </a>
                  </li>
                </Fragment>
              )}
              {this.$present(doc.children) && (
                <Fragment>
                  <li className={'has-child ' + `${this.isCurrentCursor(doc) ? 'active' : ''}`}>
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        doc.focus = !doc.focus;
                        doc.selectFocus = doc.focus;
                        this.forceUpdate();
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: doc.name }}></span>
                      <div className="arrow">
                        <i className={'mdi font-weight-800 ' + `${doc.focus ? 'mdi-minus' : 'mdi-plus'}`}></i>
                      </div>
                    </a>
                    {doc.children !== undefined && doc.children.length > 0 && doc.focus && (
                      <Fragment>
                        <ul className="bootpay-sub-menu">
                          {(doc.children || []).map((child, i) => (
                            <Fragment key={i}>
                              <li className={`${this.isCurrentCursor(child) ? 'active' : ''}`}>
                                <a href={`#${child.id}`}>
                                  <span dangerouslySetInnerHTML={{ __html: child.name }}></span>
                                </a>
                              </li>
                            </Fragment>
                          ))}
                        </ul>
                      </Fragment>
                    )}
                  </li>
                </Fragment>
              )}
            </Fragment>
          ))}
        </ul>
      </aside>
      <article className="bootpay-body">
        <header className="ps-3 pe-3 fs-8 font-weight-600 text-end">문서버전: {DocsVersion}</header>
        {(this.docs || []).map((doc, i) => (
          <Fragment key={i}>
            {doc.component !== undefined && <Fragment>{doc.component}</Fragment>}
            {doc.children !== undefined && doc.children.length > 0 && (
              <Fragment>
                {(doc.children || []).map((child, i) => (
                  <Fragment key={i}>{child.component}</Fragment>
                ))}
              </Fragment>
            )}
          </Fragment>
        ))}
      </article>
      <DocsSearch
        ref={ref => {
          this.searchRef = ref;
        }}
        docs={this.docs}
      ></DocsSearch>
    </main>
  );
}
