import React from 'react'
import { ReactComponent } from 'support-js/react/component'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.sass'

export class BootSkeleton extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="bootpay-skeleton">
                <div className="left">
                    <Skeleton width="300px" height="40px"/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '2rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="35%" style={ { marginTop: '2rem' } }/>
                    <Skeleton width="300px" height="40px" style={ { marginTop: '2rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '2rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                    <Skeleton width="100%" height="20px" style={ { marginTop: '0.5rem' } }/>
                </div>
                <div className="right">
                    <Skeleton width="100%" height="45%"/>
                    <Skeleton width="100%" height="45%" style={ { marginTop: '2rem' } }/>
                </div>
            </div>
        )
    }
}