import { ReactComponent } from 'support-js/react/component'
import { computed } from 'mobx'
// @ts-ignore
import { CodeFile } from 'components/code/file.ts.erb'
import Prism from 'prismjs'

export class BootpayComponent extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    getCode(path) {
        return CodeFile[path] !== undefined ? CodeFile[path].codes : undefined
    }

    getResponse(path) {
        return CodeFile[path] !== undefined ? CodeFile[path].response : undefined
    }

    /**
     * Platform 이름을 리턴한다
     * Comment by Gosomi
     * @date: 2022-03-30
     */
    @computed get platformName() {
        switch (this.$session.frontLanguage) {
            case 'web':
                return 'WEB'
            case 'android':
                return 'ANDROID'
            case 'ios':
                return 'iOS'
            case 'flutter':
            case 'react':
            case 'unity':
                return 'ANDROID/iOS'
        }
    }

    /**
     * front 언어 변경
     * Comment by Gosomi
     * @date: 2022-07-06
     */
    updateFrontLanguage(language: string) {
        this.$session.updateFrontLanguage(language)
        this.updateLocationUrl()
        setTimeout(() => {
            Prism.highlightAll()
        }, 100)
    }

    /**
     * backend 언어 변경
     * Comment by Gosomi
     * @date: 2022-07-06
     */
    updateBackendLanguage(language: string) {
        this.$session.updateBackendLanguage(language)
        this.updateLocationUrl()
        setTimeout(() => {
            Prism.highlightAll()
        }, 100)
    }

    /**
     * URL을 변경한다
     * Comment by Gosomi
     * @date: 2022-07-06
     */
    updateLocationUrl() {
        const hash = location.hash.replace(/#/gi, '')
        window.history.replaceState(null, null, `?front=${ this.$session.storageData.front }&backend=${ this.$session.storageData.backend }${ this.$present(hash) ? `#${ hash }` : '' }`)
    }
}