import React, { RefObject } from 'react'
import './style.sass'
import { isPresent } from 'support-js/support'

export interface BootProgressOption {
    timeout?: number
}

export class BootProgress extends React.Component<any, any> {
    timeoutInstance
    option: BootProgressOption

    constructor(props) {
        super(props)
        this.state = {
            isShow: false,
            message: ''
        }
        this.timeoutInstance = undefined
        this.option = {
            timeout: 60
        }
    }

    template() {
        return {
            _html: this.state.message
        }
    }

    show(message: string, option: BootProgressOption = {}) {
        this.setState({
            isShow: true,
            message: message
        })
        this.option = option
        this.option.timeout = this.option.timeout || 60
        this.timeoutInstance = setTimeout(() => {
            this.setState({
                isShow: false
            })
        }, this.option.timeout * 1000)
    }

    hide() {
        this.setState({
            isShow: false,
            message: ''
        })
    }

    isProgress() {
        return this.state.isShow
    }

    render() {
        return this.state.isShow && (
            <div className="ng-progress-background">
                <div className="progress-message-window">
                    <div className="bootpay-loading">
                        <div className="bootpay-spinner"></div>
                    </div>
                    <div className="bootpay-text">
                        <span className="bootpay-inner-text">{ this.state.message }</span>
                    </div>
                </div>
            </div>
        )
    }
}

export const bootProgressRef: RefObject<BootProgress> = React.createRef()

export const showProgress = (message: string, option: BootProgressOption = {}) => {
    return isPresent(bootProgressRef.current) ? bootProgressRef.current.show(message) : null
}

export const hideProgress = () => {
    return isPresent(bootProgressRef.current) ? bootProgressRef.current.hide() : null
}

export const isProgress = () => {
    return isPresent(bootProgressRef.current) && bootProgressRef.current.isProgress()
}